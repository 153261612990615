<template>
  <v-container>
    <div class="d-flex align-center">
      <h1 class="text-h4 mr-6">Data Jemaat</h1>
      <v-btn
        color="accent"
        small
        :rounded="!isOnPC"
        href="https://docs.google.com/spreadsheets/d/1XgsrF_ch8YGh72ZyDwLgOWYudllTNjlu5J7vcMgfS80/edit#gid=793835288"
        target="_blank"
        v-if="false"
      >
        {{ isOnPC ? 'Lihat Semua' : '' }}
        <v-icon small v-if="!isOnPC">mdi-table</v-icon>
      </v-btn>
      <v-btn
        color="primary"
        small
        :rounded="!isOnPC"
        href="https://forms.gle/SZdoT7YCmLTQBhsw8"
        target="_blank"
        class="ml-1"
      >
        {{ isOnPC ? 'Isi Formulir' : '' }}
        <v-icon small v-if="!isOnPC">mdi-file-document-edit</v-icon>
      </v-btn>
    </div>
    <div>
      <table class="mt-4" style="border-collapse: collapse">
        <tr><td class="pr-6">Jumlah gereja</td><td v-text="churches" /></tr>
        <tr><td class="pr-6">Total jemaat</td><td v-text="tableData.length" /></tr>
        <tr><td class="pr-6">Laki-laki</td><td v-text="male" /></tr>
        <tr><td class="pr-6">Perempuan</td><td v-text="female" /></tr>
      </table>
    </div>
    <v-data-table
      :loading="loading"
      :headers="mainTable.headers"
      :items="tableData"
      item-key="name"
      :search="keyword"
    >
      <template v-slot:top>
        <div class="d-md-flex align-center justify-space-between">
          <div style="width: 100%">
            <v-text-field label="Cari nama jemaat/gereja" v-model="keyword" prepend-inner-icon="mdi-magnify">
            </v-text-field>
          </div>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>

export default {
  name: 'DataJemaat',

  data () {
    return ({
      loading: false,

      rawTableData: [],
      keyword: '',

      tableId: '1XgsrF_ch8YGh72ZyDwLgOWYudllTNjlu5J7vcMgfS80',
      range: 'A2:K999999',

      mainTable: {
        headers: [
          {
            text: '',
            value: 'name'
          },
          {
            text: 'Jemaat',
            value: 'jemaat'
          }
        ]
      }
    })
  },

  computed: {
    gSheetApiKey () {
      return process.env.VUE_APP_GOOGLE_API_KEY
    },

    tableData () {
      return this.$data.rawTableData.map(row => ({
        name: row[1],
        jemaat: row[8]
      }))
    },

    churches () {
      const unique = []
      this.$data.rawTableData.forEach(row => {
        if (!unique.includes(row?.[8])) unique.push(row?.[8])
      })
      return 44
    },

    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    male () {
      return this.$data.rawTableData.filter(row => row?.[2]?.toLowerCase() === 'laki-laki').length ?? 0
    },
    female () {
      return this.$data.rawTableData.length - this.male ?? 0
    }
  },

  methods: {
    getTableData () {
      var requestUrl = new URL(`https://sheets.googleapis.com/v4/spreadsheets/${this.$data.tableId}/values/${encodeURIComponent(this.$data.range)}`)
      requestUrl.searchParams.append('key', this.gSheetApiKey)
      requestUrl.searchParams.append('valueRenderOption', 'UNFORMATTED_VALUE')

      return new Promise((resolve, reject) => {
        window.fetch(requestUrl)
          .then(response => response.json())
          .then(data => { resolve(data.values) })
          .catch(e => { reject(e) })
      })
    }
  },

  async mounted () {
    this.$data.loading = true

    this.$data.rawTableData = await this.getTableData()

    this.$data.loading = false
  }
}
</script>
